<template>
  <div class="search-container">
    <el-form
      :disabled="getOrgDetails && !getOrgDetails.accountSetup && !isSimpatraAdmin"
      class="search-form"
      @submit.native.prevent
      @keyup.enter.native="navigateToQueryResults"
    >
      <el-input v-model="query" size="medium" :placeholder="`Search ${placeholderText}`" :maxlength="50">
        <el-select slot="prepend" v-model="categorySlug" class="category-prepend">
          <el-option label="All" value="all-products" />
          <el-option label="Pharmaceuticals" value="pharmaceuticals" />
          <el-option label="Medical Supplies" value="medical-supplies" />
          <el-option label="Pharmacy" value="pharmacy" />
          <el-option label="Nutraceuticals" value="nutraceuticals" />
          <el-option label="Medical Equipment" value="medical-equipment" />
        </el-select>
        <el-button slot="append" class="button-append" @click="navigateToQueryResults">
          <font-awesome-icon icon="search" />
        </el-button>
      </el-input>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import userMe from "@/services/user-me";

export default {
  name: "Searchbar",
  computed: {
    ...mapGetters({
      searchQuery: "marketplaceSearch/getQuery",
      searchCategory: "marketplaceSearch/getCategory",
      getCurrentUser: "getCurrentUser",
    }),
    ...mapGetters("accountsModule", ["getOrgDetails"]),
    query: {
      get() {
        return this.searchQuery;
      },
      set(query) {
        this.commitQuery(query);
      },
    },
    categorySlug: {
      get() {
        return this.searchCategory;
      },
      set(categorySlug) {
        this.commitCategory(categorySlug);
      },
    },
    placeholderText() {
      return this.categorySlug.split("-").join(" ");
    },
    getMyRoles() {
      return userMe.getMyRoles();
    },
    isSimpatraAdmin() {
      return this.getMyRoles.isSimpatraAdmin;
    },
  },
  methods: {
    ...mapActions({
      commitQuery: "marketplaceSearch/commitSetQuery",
      commitCategory: "marketplaceSearch/commitSetCategory",
    }),
    async navigateToQueryResults() {
      this.$router.push({ name: "SearchList", query: { q: this.query, c: this.categorySlug } });
    },
  },
};
</script>

<style lang="scss">
.search-container {
  display: flex;
  flex: 1;
  justify-content: center;

  .search-form {
    width: 100%;
  }
  .category-prepend {
    width: 75px;
  }

  ::v-deep {
    .el-input-group__append {
      background-color: $orange;
      color: #fff;
      border: none;
      &:hover {
        opacity: 0.8;
      }
    }
    .el-input__inner:focus {
      border-color: #fff;
    }
  }
}

@media screen and (max-width: 425px) {
  .search-container {
    .el-input__inner {
      height: 28px !important;
      font-size: 9px !important;
    }

    .category-prepend {
      width: 48px;
      .el-input__inner {
        padding: 0 5px;
      }
    }

    .el-input__suffix {
      display: flex;
      align-items: center;
    }

    .el-input.el-input--suffix {
      top: -2px;
    }
  }
}
</style>
