<template>
  <BaseModal>
    <BaseCard title="Add New">
      <template slot="action">
        <BaseButton
          class="cancel"
          icon="el-icon-close"
          @click="exitForm"
        >
          Cancel
        </BaseButton>
      </template>
      <!-- <PatientLocationInfoForm
        action="add"
        @toggleForm="exitForm" /> -->
    </BaseCard>
  </BaseModal>
</template>

<script>
import BaseButton from '@/components/common/BaseButton.vue';
import BaseModal from '@/components/common/BaseModal.vue';
import BaseCard from '@/components/common/BaseCard.vue';
// import PatientLocationInfoForm from '@/components/common/PatientLocationInfoForm.vue';

export default {
  name: 'PatientLocationInfo',
  components: {
    BaseButton,
    BaseModal,
    BaseCard,
    // PatientLocationInfoForm,
  },
  data() {
    return {
      tierName: '',
    };
  },
  methods: {
    exitForm() {
      this.$emit('toggleAddLocation');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
